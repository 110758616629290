/* eslint-disable import/order */

// This is called a "splat route" and as it's in the root `/app/routes/`
// directory, it's a catchall. If no other routes match, this one will and we
// can know that the user is hitting a URL that doesn't exist. By throwing a
// 404 from the loader, we can force the error boundary to render which will
// ensure the user gets the right status code and we can display a nicer error
// message for them than the Remix and/or browser default.

import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { Link, useLocation } from '@remix-run/react'

export async function loader() {
	throw new Response('Not found', { status: 404 })
}

export default function NotFound() {
	// due to the loader, this component will never be rendered, but we'll return
	// the error boundary just in case.
	return <ErrorBoundary />
}

export function ErrorBoundary() {
	let { key } = useLocation()

	return (
		<GeneralErrorBoundary
			statusHandlers={{
				404: () => (
					<div>
						<div className="absolute inset-0 bg-gradient-to-b from-primary-dark/0 from-65% to-primary/50 lg:from-50%"></div>
						<section className="relative mx-auto flex max-w-screen-sm flex-col gap-12 px-8 pt-40 lg:max-w-screen-lg lg:gap-16">
							<div className="mx-auto max-w-screen-xl">
								<p className="text-base font-bold md:text-2xl md:font-normal lg:text-2xl 2xl:text-3xl 3xl:text-3xl">
									Oops- still working on this part of the site.
								</p>
								<p className="text-center text-sm md:pl-20 md:text-base lg:pl-40 lg:text-xl 2xl:pl-64 2xl:text-xl">
									<span>
										<Link
											to="/mailing-list"
											className="group text-primary-dark focus-within:text-primary hover:text-primary"
										>
											<span className="inline-block transition group-hover:-translate-x-1">
												join our official mailing list
											</span>
										</Link>{' '}
										and be the first <br className="md:hidden" /> to know our
										launch date!
									</span>
								</p>
							</div>
							{/* <div className="mx-auto flex !hidden max-w-screen-sm flex-col items-center gap-3 text-center">
								<p className="font-display text-base font-semibold text-primary-dark/80 md:max-w-none md:text-lg md:leading-none lg:text-2xl">
									autumn 2024
								</p>
								<Button asChild variant="cta" key={key}>
									<Link
										to="/mailing-list"
										className="!py-2 text-base duration-500 ease-out animate-in fade-in-30 slide-in-from-bottom lg:text-lg 2xl:text-2xl 3xl:text-3xl"
									>
										join the mail list
									</Link>
								</Button>
								<p className="font-light lg:text-lg 2xl:text-xl">
									( don't worry, we don't believe in spam )
								</p>
							</div> */}

							<aside className="mx-auto max-w-screen-md pb-20 text-center text-lg italic lg:text-xl">
								If you're interested in joining our team as an instructor,
								please share your email. Our first cohort of instructors will
								enjoy unique benefits, such as special promotions, professional
								photo sessions, and access to networking events.
							</aside>
						</section>
					</div>
				),
			}}
		/>
	)
}
